import React, { cloneElement, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  NOT_PAID,
  NO_INVOICE,
  PAID,
  PARTIALLY_PAID,
  ENFORCEMENT_TRADINDG_LICENSE,
  ENFORCEMENT_OPERRATIONAL_PERMIT,
  ENFORCEMENT_PROPERTY_TAX,
  VIEW_ENFORCEMENT_OPERRATIONAL_PERMIT,
  VIEW_ENFORCEMENT_TRADINDG_LICENSE,
  VIEW_ENFORCEMENT_PROPERTY_TAX,
  APP_AUTHORITY,
  VERIFICATION_AUTHORITY,
  DIVISION_ADMIN,
} from "../../constants/webapp";
import axiosInstance from "../../utils/axios";
import { statusTitle } from "../../utils/statusUtils";
import {
  downloadThroughAnchorLink,
  displayDocuments,
} from "../../utils/helperUtil";
import {
  DownloadAnchorButton,
  DownloadLink,
} from "../../components/common/Button";
import { API_GENERATE_BILL, INVOICE_API, PRN_API } from "../../constants/api";
import { openModal } from "../../utils/modalUtils";
import TradeLicenseAssessment from "../../components/pages/trade-license/TradeLicenseAssessment";
import useGenericSet from "../common/useGenericSet";
import PropertyTaxAssessment from "../../components/pages/property-rates/property-tax/PropertyAssessment";
import OutdoorAssessment from "../../components/pages/outdoor-advertising/OutdoorAssessment";
import MarketRentAssessment from "../../components/pages/market-rent/MarketRentAssessment";
import OperationPermitAssessment from "../../components/pages/operational-permit/OperationalPermitAssessment";
import LocalHotelTaxAssessment from "../../components/pages/local-hotel-tax/LocalHotelTaxAssessment";
import RentAssessment from "../../components/pages/rent-and-rates/RentAssessment";
import LocalServiceTax from "../../components/pages/local-service-tax/LocalServiceTax";
import { FaCheckCircle, FaEye } from "react-icons/fa";
import GroundRentAssessment from "../../components/pages/ground-rent/GroundRentAssessment";
import ParkUserFeesAssessment from "../../components/pages/park-user-fees/ParkUserFeesAssessment";
import DevelopmentPermitAssessment from "../../components/pages/developement-control/development-permit/DevelopmentPermitAssessment";
import BuildingPermitAssessment from "../../components/pages/developement-control/building-permit/BuildingPermitAssessment";
import ChainlinkPermitAssessment from "../../components/pages/developement-control/chain-link-permit/ChainlinkPermitAssessment";
import ExcavationPermitAssessment from "../../components/pages/developement-control/excavation-permit/ExcavationPermitAssessment";
import HoardingPermitAssessment from "../../components/pages/developement-control/hoarding-permit/HoardingPermitAssessment";
import DemolitionPermitAssessment from "../../components/pages/developement-control/demolition-permit/DemolitionPermitAssessment";
import OutdoorAdvertisementPermitAssessment from "../../components/pages/developement-control/outdoor-advertisement-permit/OutdoorAdvertisementPermitAssessment";
import OccupationalPermitAssessment from "../../components/pages/developement-control/occupational-permit/OccupationalPermitAssessment";
import SubdivisionPermitAssessment from "../../components/pages/developement-control/subdivision-permit/SubdivisionPermitAssessment";
import RenovationPermitAssessment from "../../components/pages/developement-control/renovation-permit/RenovationPermitAssessment";
import LeaseApplicationAssessment from "../../components/pages/developement-control/lease-application/LeaseApplicationAssessment";
import CondominiumPermitAssessment from "../../components/pages/developement-control/condominium-permit/CondominiumPermitAssessment";
import MarketDuesBillAssessment from "../../components/pages/market-dues/MarketDuesBillAssessment";
import { Visibility } from "../../components/common/Visibility";
import { useAppContext } from "../../context/AppContext";
import { useAuth } from "../../context/authContext";
import { parse } from "url";
import useEnforcementAudit from "../../hooks/enforcement/useEnforcementAudit";
import GenericView from "../../components/common/GenericView";

function useAssessments() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [assessments, setAssessments] = useState([]);
  const [submitting, setSubmiting] = useState(false);
  const [_, setGrade] = useState({});
  const {
    uploadData: postBill,
    success: billSucess,
    error: billError,
  } = useGenericSet();
  const navigate = useNavigate();
  const { user } = useAuth();

  const { search, state } = useLocation();
  const { query } = parse(search, true);
  const has_arrears = query?.has_arrears;
  const tax_api = query?.tax_api;
  const [permitName, setPermitName] = useState("");
  const { businessSealDetails,  taxPayerEngagementDetails} = useEnforcementAudit();

  useEffect(() => {
    if (state) {
      setPermitName(state.license);
    }
  }, [state?.license]);

  const isEnforcementPermit = [
    ENFORCEMENT_TRADINDG_LICENSE,
    ENFORCEMENT_OPERRATIONAL_PERMIT,
    ENFORCEMENT_PROPERTY_TAX,
  ].includes(permitName);

  const {
    query: { status },
  } = parse(search, true);

  const renderAssessment = (tax, tax_id, period_id, date) => {
    let body = null;

    switch (tax) {
      case "trade_license":
        body = <TradeLicenseAssessment />;
        break;
      case "property":
        body = <PropertyTaxAssessment />;
        break;
      case "outdoor_advertising":
        body = <OutdoorAssessment />;
        break;
      case "market_rent":
        body = <MarketRentAssessment />;
        break;
      case "operational_permit":
        body = <OperationPermitAssessment />;
        break;
      case "rent_and_rates":
        body = <RentAssessment />;
        break;
      case "ground_rent":
        body = <GroundRentAssessment />;
        break;
      case "lht":
        body = <LocalHotelTaxAssessment date={date} />;
        break;

      case "lst":
        body = <LocalServiceTax />;
        break;

      case "development_permit":
        body = <DevelopmentPermitAssessment period={period_id} />;
        break;

      case "building_operation":
        body = <BuildingPermitAssessment period={period_id} />;
        break;

      case "chainlink_permit":
        body = <ChainlinkPermitAssessment period={period_id} />;
        break;

      case "excavation_permit":
        body = <ExcavationPermitAssessment period={period_id} />;
        break;

      case "hoarding_permit":
        body = <HoardingPermitAssessment period={period_id} />;
        break;

      case "demolition_permit":
        body = <DemolitionPermitAssessment period={period_id} />;
        break;

      case "outdoor_advertisement_permit":
        body = <OutdoorAdvertisementPermitAssessment period={period_id} />;
        break;

      case "occupation_permit":
        body = <OccupationalPermitAssessment period={period_id} />;
        break;

      case "subdivision_consolidation_permit":
        body = <SubdivisionPermitAssessment period={period_id} />;
        break;

      case "renovation_permit":
        body = <RenovationPermitAssessment period={period_id} />;
        break;

      case "lease_application":
        body = <LeaseApplicationAssessment period={period_id} />;
        break;

      case "condominium_permit":
        body = <CondominiumPermitAssessment period={period_id} />;
        break;

      case "market_dues":
        body = <MarketDuesBillAssessment period={period_id} />;
        break;

      case "park_user_fees":
        body = <ParkUserFeesAssessment period={period_id} />;
        break;

      default:
        body = <div>No revenue source provided</div>;
    }
    return body ? cloneElement(body, { tax_id, period_id }) : null;
  };

  const getEnforcementDetails = (invoice, enforcement) => {
    const enforcementDetails = enforcement === "seal" ? 
      businessSealDetails(invoice) : taxPayerEngagementDetails(invoice); 

    return (
      <GenericView viewData={enforcementDetails || {}} />
    );
  };

  const handleBillGeneration = (tax, tax_id, period_id, date) => {
    openModal({
      formId: "bill-form",
      body: renderAssessment(tax, tax_id, period_id, date),
      okText: "Generate",
      cancelText: "Cancel",
      type: "form",
    })
      .then((payload) => {
        postBill({
          api: API_GENERATE_BILL,
          params: { ...payload, tax_id, period: period_id },
        });
      })
      .catch((err) => {});
  };

  useEffect(() => {}, [billSucess, billError]);

  const statusOptions = (
    invoice,
    objStatus,
    tax,
    tax_id,
    period_id,
    date,
    current,
    index,
    reminderNoticeAttachments,
    demandNoticeAttachments
  ) => {
    let options;
    let subtitle;

    let tax_names = [
      "building_operation",
      "development_permit",
      "occupation_permit",
      "subdivision_consolidation_permit",
      "lease_application",
      "condominium_permit",
      "demolition_permit",
      "excavation_permit",
      "hoarding_permit",
      "chainlink_permit",
      "outdoor_advertisement_permit",
      "renovation_permit",
    ];

    // PAFs button
    const PAFs = <DownloadLink title="[PAFS]" paf />;
    const DEMAND = <DownloadLink title="[DEMAND]" />;

    switch (objStatus) {
      case NO_INVOICE:
        subtitle = "No Assessment";
        status === "deactivated"
          ? (options = {})
          : (options = {
              button: (
                <DownloadLink
                  color="green"
                  icon={FaCheckCircle}
                  onClick={() =>
                    handleBillGeneration(tax, tax_id, period_id, date)
                  }
                  title="[Submit Assessment]"
                />
              ),
            });
        break;

      case NOT_PAID:
        subtitle = "Not Paid";
        options = {
          link:
            has_arrears === "true"
              ? `/assessed-return-period${tax_api}/${tax_id}/${tax}`
              : `/paf/create/invoice/${tax}/${invoice.id}`,
          title: PAFs,
        };
        break;

      case PAID:
        subtitle = "Paid";
        options = {
          link: `/paf/create/invoice/${tax}/${invoice.id}`,
          title: PAFs,
        };
        break;
      case PARTIALLY_PAID:
        subtitle = "Partially Paid";
        options = {
          link: `/paf/create/invoice/${tax}/${invoice.id}`,
          title: PAFs,
        };
        break;
      default:
        options = {};
        subtitle = "";
    }

    const openPafs = (item) => {
      navigate(`/paf/create/invoice/${tax}/${invoice.id}`, {
        state: { document_data: item },
      });
    };

    const invoiceAction = (noticeType, action) => {
      navigate(
        `${
          tax === "operational_permit"
            ? VIEW_ENFORCEMENT_OPERRATIONAL_PERMIT
            : tax === "trade_license"
              ? VIEW_ENFORCEMENT_TRADINDG_LICENSE
              : VIEW_ENFORCEMENT_PROPERTY_TAX
        }/view/${tax_id}`,
        {
          state: {
            invoice_id: invoice.id,
            permit_id: tax_id,
            status: objStatus,
            ref: invoice.ref,
            action,
            notice_type: noticeType,
            has_arrears_balance: state?.document_data?.has_arrears_balance,
            suffix: state?.suffix,
            tax,
            amount: invoice.amount
          },
        }
      );
    };

    const showEnforcementDetails = (invoice, enforcement) => {
      openModal({
        title: `${enforcement === "seal" ? "Business Seal Details" : "Tax Payer Engagement Details"}`,
        body: getEnforcementDetails(invoice, enforcement),
        hideActions: true,
      });
    };

    const LinkComponent = (props) => {
      const { contextData } = useAppContext();
      const userRole = contextData?.role_name;
      let current_date = new Date();
      const certificate_user_roles = [
        "Admin",
        "Approving Authority",
        "Parish Admin",
        "Division Admin",
        "Verification Authority",
      ];

      return (
        <>
          {options?.link ? (
            <>
              {/* Option 1 */}
              <Link
                class="mr-2"
                style={{ padding: 5, paddingBottom: 0 }}
                to={options?.link}
              >
                {options?.title}
              </Link>

              {/* Option 2 */}
              <Visibility
                show={
                  ["Paid", "Partially Paid", "Not Paid"].includes(subtitle) &&
                  ["lht", "lst"].includes(tax)
                }
              >
                <Link
                  class="mr-2"
                  style={{ padding: 5, paddingBottom: 0 }}
                  to={`/reassessment/${tax}/${tax_id}/${invoice?.id}/${
                    tax === "lht" ? props?.date : ""
                  }`}
                >
                  [Reassessment]
                </Link>
              </Visibility>

              {/* Option 3 */}
              <Visibility
                show={
                  ["Paid"].includes(subtitle) &&
                  ["trade_license", "operational_permit"].includes(tax) &&
                  (props?.current === "y" ||
                    (props?.current === "n" &&
                      certificate_user_roles.includes(userRole)))
                }
              >
                <DownloadAnchorButton
                  url={`/billings/invoices/print-object-period-certificate/?tax_id=${tax_id}&period=${period_id}`}
                  fileName="Certificate"
                  title="[Certificate]"
                />
              </Visibility>

              {subtitle === "Not Paid" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingBottom: "12px",
                  }}
                >
                  {/* <Visibility
                    show={
                      certificate_user_roles.includes(userRole)
                    }
                  > */}
                  <DownloadAnchorButton
                    color="red"
                    url={`${PRN_API}print-demand-notice/?id=${invoice?.id}`}
                    fileName="Demand Notice"
                    title="[Demand Notice]"
                  />
                  {/* </Visibility> */}
                  {isEnforcementPermit && (
                    <>
                      {invoice.demand_notice_acknowledgment_status ===
                      "Demand Notice Acknowledged" ? (
                        <div>
                          {Object.keys(demandNoticeAttachments).map((key) => {
                            return demandNoticeAttachments[key];
                          })}
                        </div>
                      ) : (
                        <div>
                          <button
                            className="btn-link"
                            onClick={() =>
                              invoiceAction("Demand Notice", "acknowledge")
                            }
                          >
                            [Acknowledge]
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}

              {
                tax === "property" && isEnforcementPermit &&
                <>
                  {invoice.engagements.length > 0 ? (
                    <div>
                      <DownloadLink
                        icon={FaEye}
                        onClick={() => showEnforcementDetails(props?.item, "taxpayer_engagement")}
                        title="[Taxpayer Engagement]"
                      />
                    </div>
                  ) : (
                    <div>
                      <button
                        className="btn-link"
                        onClick={() => invoiceAction("Taxpayer Engagement", "engage")}
                      >
                        [Taxpayer Engagement]
                      </button>
                    </div>
                  )}
                </>
              }

              {invoice.demand_notice_status === "Demand Notice Downloaded" &&
              isEnforcementPermit ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingBottom: "12px",
                  }}
                >
                  <DownloadAnchorButton
                    color="red"
                    url={`billings/prns/print-reminder-notice/?id=${invoice?.id}`}
                    fileName="Reminder Notice"
                    title="[Reminder Notice]"
                  />
                  {invoice.reminder_notice_acknowledgment_status ===
                  "Reminder Notice Acknowledged" ? (
                    <div>
                      {Object.keys(reminderNoticeAttachments).map((key) => {
                        return reminderNoticeAttachments[key];
                      })}
                    </div>
                  ) : (
                    <div>
                      <button
                        className="btn-link"
                        onClick={() =>
                          invoiceAction("Reminder Notice", "acknowledge")
                        }
                      >
                        [Acknowledge]
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <></>
              )}

              {invoice.reminder_notice_acknowledgment_status ===
                "Reminder Notice Acknowledged" && isEnforcementPermit ? (
                <>
                  {invoice.sealing_status === "Sealed" ? (
                    <DownloadLink
                      icon={FaEye}
                      onClick={() => showEnforcementDetails(props?.item, "seal")}
                      title="[Sealed]"
                    />
                  ) : (
                    <div>
                      <button
                        className="btn-link"
                        onClick={() => invoiceAction("Reminder Notice", "seal")}
                      >
                        [Seal]
                      </button>
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          ) : options?.title === PAFs ? (
            <Link
              class="mr-2"
              style={{ padding: 5, paddingBottom: 0 }}
              to={options?.link}
            >
              {options?.title}
            </Link>
          ) : null}

          {tax_names?.includes(tax)
            ? current_date.getFullYear() === parseInt(date)
              ? options?.button && [0, 1].includes(user?.role?.can_public)
                ? options.button
                : null
              : null
            : options?.button && [0, 1].includes(user?.role?.can_public)
              ? options.button
              : null}
        </>
      );
    };

    return { LinkComponent, subtitle };
  };

  // Get list of Assessments
  const getAssessments = (search) => {
    setLoading(true);
    axiosInstance
      .get(`billings/periods/${search}`)
      .then((res) => {
        // handle success
        setAssessments(res.data);
        setLoading(false);
      })
      .catch((err) => {
        // handle error
        setLoading(false);
        setError(err);
      });
  };

  const getUsers = () => {
    setLoading(true);
    axiosInstance
      .get(`configurables/trading-license-amounts/?grade=3&category=45`)
      .then(({ data }) => {
        // handle success
        setGrade(data);
      })
      .catch((err) => {
        // handle error
        setError(err);
      });
  };

  const generateBill = (data) => {
    setSubmiting(true);
    axiosInstance
      .post("billings/invoices/generate-bill/", data)
      .then(({ tax_id }) => {
        // handle success
        setSuccess("Submitted Successfully");
        setSubmiting(false);
      })
      .catch((err) => {
        // handle error
        setSubmiting(false);
        setError(err);
      });
  };

  const viewEnforcementAudit = (item) => {
    navigate(`/enforcement-audit/${item.id}`, {
      state: {
        item,
      },
    });
  };

  const assessmentsTableData = (data, tax, tax_id, state) => [
    ...data.map((item, index) => {
      let reminderNoticeAttachments;
      let demandNoticeAttachments;

      if (item.invoice_details.acknowledgments !== undefined) {
        item.invoice_details.acknowledgments.map((item) => {
          if (item.notice_type_display === "Reminder Notice") {
            reminderNoticeAttachments = {
              ...displayDocuments(
                item.acknowledgment_attachments
                  ? item.acknowledgment_attachments
                  : {}
              ),
            };
          } else {
            demandNoticeAttachments = {
              ...displayDocuments(
                item.acknowledgment_attachments
                  ? item.acknowledgment_attachments
                  : {}
              ),
            };
          }
        });
      }

      const { LinkComponent, subtitle } = statusOptions(
        item.invoice_details,
        item.invoice_status,
        tax,
        tax_id,
        item.id,
        item.name,
        item.current,
        index,
        reminderNoticeAttachments,
        demandNoticeAttachments
      );

      const row = {
        name: item.name,
        actions: (
          <LinkComponent
            date={item?.name}
            state={state}
            period_id={item?.id}
            current={item?.current}
            item={item}
          />
        ),
        payment_status: subtitle,
        enforcement_audit: (
          <button
            className="btn-link"
            onClick={() => viewEnforcementAudit(item)}
          >
            View Audit Report
          </button>
        ),
        status:
          item?.invoice_status === PAID ? (
            <>
              Certificate{" "}
              <DownloadLink
                onClick={() =>
                  downloadThroughAnchorLink(
                    `${INVOICE_API}print-certificate/?invoice_id=${item?.invoice_details?.id}`,
                    "Certificate"
                  )
                }
                title="[Certificate]"
              />
            </>
          ) : (
            <>No Certificate </>
          ),
      };
      return row;
    }),
  ];

  const viewData = (item) => ({
    name: item.name,
    category: item.category_details?.name,
    developer: item.developer,
    "Contact name": item.contact_name,
    "Contact phone": item.contact_phone,
    "Contact email": item.contact_email,
    status: statusTitle(item.status),
    "Created at": item.created_at,
    "Created by": item.created_by,
  });

  const assessmentsTableColumns = [
    { id: "name", header: "Period", filter: {} },
    isEnforcementPermit
      ? { id: "enforcement_audit", header: "Enforcement Audit", filter: {} }
      : { id: "payment_status", header: "Status", filter: {} },
    { id: "actions", header: "Actions" },
  ];

  return {
    assessmentsTableData,
    assessmentsTableColumns,
    getAssessments,
    assessments,
    generateBill,
    viewData,
    statusOptions,
    submitting,
    error,
    success,
    billSucess,
    billError,
    loading,
    getUsers,
  };
}

export default useAssessments;

import React, { useEffect } from "react";
import { FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  COLOR_RED,
  DIV_NAME,
  MARKET_DUES,
  MUN_NAME,
  PAR_NAME,
  STATUS_APPROVED,
  STATUS_DEFFERED,
  STATUS_PENDING,
  STATUS_REJECTED,
  STR_NAME,
  VILL_NAME,
  DAILY_PAYMENT_INTERVAL,
  WEEKLY_PAYMENT_INTERVAL,
  MONTHLY_PAYMENT_INTERVAL,
  QUARTERLY_PAYMENT_INTERVAL,
  ANNUAL_PAYMENT_INTERVAL,
  BI_ANNUAL_PAYMENT_INTERVAL
} from "../../constants/webapp";
import { returnObject } from "../../utils/helperUtil";
import DetailsCard from "../../components/common/cards/DetailsCard";

const useMarketDues = () => {
  const marketDuesTableData = (_, data) => [
    ...data.map((item) => {
      const row = {
        branch_code: item?.branch_code,
        market_name: item?.market_name,
        amount: Number(item?.amount || 0).toLocaleString(),
        [MUN_NAME]: item?.municipality_name,
        [DIV_NAME]: item?.division_name,
        [PAR_NAME]: item?.parish_name,
        [VILL_NAME]: item?.village_name,
        [STR_NAME]: item?.street_name,
        customer_name: item?.owner_name,
        customer_mobile: item?.mobile_number,
        created_by: item?.created_by_name,
        payment_interval: 
          item.payment_interval ? item.payment_interval.charAt(0).toUpperCase() 
          + item.payment_interval.slice(1) : "",
        invoices: 
          <Link
            style={{ color: COLOR_RED }}
            to={{
              pathname: "/assessments",
              search: `?tax_id=${item?.id}&tax=market_dues`,
              state: { document_data: item }
            }}
          >
            Assessment
          </Link>,
        actions: (
          <div style={{ whiteSpace: "nowrap" }}>
            <div style={{ whiteSpace: "nowrap" }}>
              <Link
                class="mr-2"
                style={{ padding: 5, paddingBottom: 0 }}
                to={`${MARKET_DUES}/view/${item.id}`}
              >
                <FaEye />
              </Link>
            </div>
          </div>
        ),
      };
      return row;
    }),
  ];

  const breakdownViewData = (item) => {
    return {
      category: item?.category_name,
      item: item?.item_name,
      quantity: Number(item?.quantity).toLocaleString(),
      rate: Number(item?.rate || 0).toLocaleString(),
      total: Number(item?.total || 0).toLocaleString(),
    };
  };

  const viewData = (item, isFormData) => {
    return {
      ...returnObject(!isFormData, {
        "Branch code": item?.application_number,
      }),
      "Market Name": isFormData ? item?.market_name : item?.market_name,
      Municipality: isFormData
        ? item?.municipality_name
        : item?.municipality_name,
      Division: isFormData ? item?.division_name : item?.division_name,
      Parish: isFormData ? item?.parish_name : item?.parish_name,
      Village: isFormData ? item?.village_name : item?.village_name,
      Street: isFormData ? item?.street_name : item?.street_name,
      "owner name": item?.owner_name,
      // email: item?.email || "n/a",
      "mobile number": item?.mobile_number,
      "payment_interval": item?.payment_interval,
      ...returnObject(item?.breakdown_details?.length, {
        breakdown_details: (item?.breakdown_details || []).map((it) => (
          <DetailsCard viewData={breakdownViewData(it)} />
        )),
      }),
      Amount: Number(item?.amount || 0).toLocaleString(),
      ...returnObject(!isFormData, {
        payment_status:
          item?.invoice?.payment_status?.replaceAll("_", " ").toUpperCase() ||
          "NO INVOICE",
      }),
      created_by: item?.created_by_name,
    };
  };

  const marketDuesTableColumns = () => [
    { id: "branch_code", header: "Branch code ", filter: {} },
    { id: MUN_NAME, header: "Municipality", filter: {} },
    { id: DIV_NAME, header: "Division", filter: {} },
    { id: PAR_NAME, header: "Parish ", filter: {} },
    { id: VILL_NAME, header: "Village", filter: {} },
    { id: STR_NAME, header: "Street ", filter: {} },
    { id: "market_name", header: "Market name", filter: {} },
    { id: "customer_name", header: "Customer name", filter: {} },
    { id: "customer_mobile", header: "Customer mobile", filter: {} },
    { id: "amount", header: "Amount" },
    { id: "payment_interval", header: "Payment Interval", filter: {} },
    { id: "created_by", header: "Created By", filter: {} },
    { id: "invoices", header: "Invoices" },
    { id: "actions", header: "Actions" },
  ];

  const getActions = (status) => {
    let options;

    switch (status) {
      case STATUS_PENDING:
        options = [
          { value: STATUS_APPROVED, label: "Approve" },
          { value: STATUS_DEFFERED, label: "Deffer" },
          { value: STATUS_REJECTED, label: "Reject" },
        ];
        break;

      default:
        options = [];
    }

    return options;
  };

  const getRedirect = (status) => {
    let redirect = "";
    switch (status) {
      default:
        redirect = `${MARKET_DUES}/register`;
        break;
    }

    return redirect;
  };

  const amendableFields = [
    { id: "owner name", field: "owner_name", type: "text" },
    { id: "mobile number", field: "mobile_number", type: "tel" }
  ];

  const reassessFields = [
    { id: "Amount", field: "amount", type: "number" },
    {
      id: "payment_interval",
      field: "payment_interval",
      type: "select",
      options: "payment_intervals",
    },
  ];

  return {
    marketDuesTableColumns,
    marketDuesTableData,
    viewData,
    getActions,
    getRedirect,
    amendableFields,
    reassessFields
  };
};

export const useMarketDuesFormSelect = (form, updateFormValue) => {
  const paymentIntervals = [
    {
      id: DAILY_PAYMENT_INTERVAL,
      name: "Daily"
    },
    {
      id: WEEKLY_PAYMENT_INTERVAL,
      name: "Weekly"
    },
    {
      id: MONTHLY_PAYMENT_INTERVAL,
      name: "Monthly"
    },
    {
      id: QUARTERLY_PAYMENT_INTERVAL,
      name: "Quarterly"
    },
    {
      id: ANNUAL_PAYMENT_INTERVAL,
      name: "Annually"
    },
    {
      id: BI_ANNUAL_PAYMENT_INTERVAL,
      name: "Bi-annually"
    },
  ];

  useEffect(() => {
    if (form?.payment_interval) {
      updateFormValue("payment_interval", form?.payment_interval);
    }
  }, [form?.payment_interval]);

  return {
    payment_intervals: paymentIntervals,
  }
};

export default useMarketDues;
